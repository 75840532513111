import axios from "axios";
import { useCookies } from "vue3-cookies";
import router from "./router";
import Notifications, { notify } from "notiwind";

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_BASEURL,
  // timeout: 10000,
  headers: {
    Authorization: "Bearer " + useCookies().cookies.get("Aalulbayte_api_token"),
  },
  transformResponse: [
    function (data) {
      if (JSON.parse(data).status == 403) {
        useCookies().cookies.remove("Aalulbayte_api_token");
        localStorage.removeItem("user_Aalulbayte");
        router.push({ name: "Login" });
        notify(
          {
            group: "danger",
            text: "دسترسی شما مسدود شده است!",
          },
          3000
        );
        data = JSON.parse(data);
        return data;
      } else if (JSON.parse(data).status == 401) {
        useCookies().cookies.remove("Aalulbayte_api_token");
        localStorage.removeItem("user_Aalulbayte");
        router.push({ name: "Login" });
        data = JSON.parse(data);
        return data;
      } else {
        data = JSON.parse(data);
        return data;
      }
    },
  ],
});

export default axiosInstance;
