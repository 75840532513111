<template>
  <notification></notification>
  <router-view />
</template>

<script>
import notification from './components/notification.vue';
export default {
  components: {
    notification,
  },
}
</script>

<style lang="scss">
@import "./styles/style.scss"
</style>
