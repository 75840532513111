import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";
import mixin from "./mixin";
import _enum from "./enum";
import vueCookies from "vue3-cookies";
import axiosInstance from "./axios";
import Notifications from "notiwind";
import "animate.css";
import loading from "./components/loading.vue";

const app = createApp(App);
app.config.globalProperties.axios = axiosInstance;
app.use(store);
app.use(router);
app.use(vueCookies);
app.use(Notifications);
app.component("loading", loading);
app.mixin(mixin);
app.mixin(_enum);
app.mount("#app");
