<template>
  <div class="position-relative">
    <app-bar></app-bar>
    <div class="layout">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import appBar from "@/components/appBar.vue";
export default {
  components: {
    appBar,
  },
};
</script>

<style lang="scss" scoped>
.layout {
  position: absolute;
  width: 100%;
  // padding: 0 20.9rem 0 2.5rem;
  padding-inline: 2rem;
  top: 5.5rem;
  // height: 100vh;
}

@media screen and (max-width: 991.5px) {
  .layout {
    padding-inline: 1rem;
    top: 6rem;
  }
}
</style>
