export default {
  data() {
    return {
      login: "auth/login",
      logout: "auth/log-out",
      getUsers: "admin/users/",
      getLocations: "admin/locations",
      createUser: "admin/users/create",
      categoryConfig: "admin/cms/categories/",
      chaptersList: "admin/cms/chapters/",
      sectionsList: "admin/cms/sections/",
      translation: "admin/cms/translations/",
      commentary: "admin/cms/commentaries/",
      forceUpdate: "admin/app-versions/",
      notice: "admin/explorer/notices",
      changesList: "admin/cms/categories/versions/change-list/",
      createUpdate: "admin/cms/categories/versions/",
      getNotification: "admin/notifications/",
    };
  },
};
