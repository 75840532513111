<template>
  <div class="dashboard">
    <div class="app-bar">
      <button
        class="app-bar-btn main-sidebar-btn"
        type="button"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasResponsive"
        aria-controls="offcanvasResponsive"
      >
        <i class="bi bi-list"></i>
      </button>
      <div class="d-flex gap-3">
        <button
          class="app-bar-btn"
          @click="toggleTheme"
          v-if="darkMode === true"
        >
          <i class="bi bi-moon-fill"></i>
        </button>
        <button class="app-bar-btn" @click="toggleTheme" v-else>
          <i class="bi bi-brightness-low-fill"></i>
        </button>
        <button
          class="app-bar-btn"
          data-bs-toggle="modal"
          data-bs-target="#confirmModal"
        >
          <i class="bi bi-box-arrow-left p-0"></i>
        </button>
      </div>
    </div>
  </div>
  <div
    class="main-offcanvas offcanvas offcanvas-end"
    tabindex="-1"
    id="offcanvasResponsive"
    aria-labelledby="offcanvasResponsiveLabel"
  >
    <div
      class="offcanvas-body position-relative d-flex flex-column align-items-center px-0 pt-3"
    >
      <div
        class="d-flex align-items-center flex-column w-100"
        v-if="darkMode === true"
      >
        <img
          src="@/assets/sidebar-logo-dark.png"
          class="sidebar-logo"
          alt="logo"
        />
        <img
          src="@/assets/sidebar-logo-text-dark.png"
          class="sidebar-logo-text"
          alt="logo-text"
        />
      </div>
      <div class="d-flex align-items-center flex-column w-100" v-else>
        <img
          src="@/assets/sidebar-logo-light.png"
          class="sidebar-logo"
          alt="logo"
        />
        <img
          src="@/assets/sidebar-logo-text-light.png"
          class="sidebar-logo-text"
          alt="logo-text"
        />
      </div>
      <div class="d-flex flex-column col-12 mt-4">
        <button
          class="router"
          data-bs-dismiss="offcanvas"
          data-bs-target="#offcanvasResponsive"
        >
          <router-link to="/dashboard" class="sidebar-btn">
            <div
              class="chosen-sidebar-btn"
              v-if="routeName == 'dashboard'"
            ></div>
            <div
              :class="{ 'chosen-sidebar-btn-text': routeName == 'dashboard' }"
            >
              داشبورد
            </div>
          </router-link>
        </button>
        <template
          v-for="category in Object.keys(categories)"
          :key="categories[category].id"
        >
          <button
            class="router"
            data-bs-dismiss="offcanvas"
            data-bs-target="#offcanvasResponsive"
            @click="changeCategory(category)"
          >
            <router-link
              :to="`/${categories[category].name}/dashboard`"
              class="sidebar-btn"
            >
              <div
                class="chosen-sidebar-btn"
                v-if="routeName == categories[category].name"
              ></div>
              <div
                :class="{
                  'chosen-sidebar-btn-text':
                    routeName == categories[category].name,
                }"
              >
                {{ categories[category].persianName }}
              </div>
            </router-link>
          </button>
        </template>
        <!-- <button
          class="router"
          data-bs-dismiss="offcanvas"
          data-bs-target="#offcanvasResponsive"
        >
          <router-link to="/poems/dashboard" class="sidebar-btn">
            <div class="chosen-sidebar-btn" v-if="routeName == 'poems'"></div>
            <div :class="{ 'chosen-sidebar-btn-text': routeName == 'poems' }">
              اشعار
            </div>
          </router-link>
        </button> -->
        <!-- <button
          class="router"
          data-bs-dismiss="offcanvas"
          data-bs-target="#offcanvasResponsive"
        >
          <router-link to="/biography/dashboard" class="sidebar-btn">
            <div
              class="chosen-sidebar-btn"
              v-if="routeName == 'biography'"
            ></div>
            <div
              :class="{ 'chosen-sidebar-btn-text': routeName == 'biography' }"
            >
              زندگینامه
            </div>
          </router-link>
        </button> -->
        <!-- <button
          class="router"
          data-bs-dismiss="offcanvas"
          data-bs-target="#offcanvasResponsive"
        >
          <router-link to="/hadiths/dashboard" class="sidebar-btn">
            <div class="chosen-sidebar-btn" v-if="routeName == 'hadiths'"></div>
            <div :class="{ 'chosen-sidebar-btn-text': routeName == 'hadiths' }">
              احادیث
            </div>
          </router-link>
        </button> -->
        <button
          class="router"
          data-bs-dismiss="offcanvas"
          data-bs-target="#offcanvasResponsive"
          v-if="role == roles.admin.name"
        >
          <router-link to="/users/dashboard" class="sidebar-btn">
            <div class="chosen-sidebar-btn" v-if="routeName == 'users'"></div>
            <div :class="{ 'chosen-sidebar-btn-text': routeName == 'users' }">
              فهرست کاربران
            </div>
          </router-link>
        </button>
        <button
          class="router"
          data-bs-dismiss="offcanvas"
          data-bs-target="#offcanvasResponsive"
        >
          <router-link to="/message/dashboard" class="sidebar-btn">
            <div class="chosen-sidebar-btn" v-if="routeName == 'message'"></div>
            <div :class="{ 'chosen-sidebar-btn-text': routeName == 'message' }">
              پیام روز
            </div>
          </router-link>
        </button>
        <button
          class="router"
          data-bs-dismiss="offcanvas"
          data-bs-target="#offcanvasResponsive"
          v-if="role == roles.admin.name"
        >
          <router-link to="/notification/dashboard" class="sidebar-btn">
            <div
              class="chosen-sidebar-btn"
              v-if="routeName == 'notification'"
            ></div>
            <div
              :class="{
                'chosen-sidebar-btn-text': routeName == 'notification',
              }"
            >
              فهرست اعلانات
            </div>
          </router-link>
        </button>
        <button
          class="router"
          data-bs-dismiss="offcanvas"
          data-bs-target="#offcanvasResponsive"
          v-if="role == roles.admin.name"
        >
          <router-link to="/contentUpdate/dashboard" class="sidebar-btn">
            <div
              class="chosen-sidebar-btn"
              v-if="routeName == 'contentUpdate'"
            ></div>
            <div
              :class="{
                'chosen-sidebar-btn-text': routeName == 'contentUpdate',
              }"
            >
              بروزرسانی محتوا
            </div>
          </router-link>
        </button>
        <button
          class="router"
          data-bs-dismiss="offcanvas"
          data-bs-target="#offcanvasResponsive"
          v-if="role == roles.admin.name"
        >
          <router-link to="/forceUpdate/dashboard" class="sidebar-btn">
            <div
              class="chosen-sidebar-btn"
              v-if="routeName == 'forceUpdate'"
            ></div>
            <div
              :class="{ 'chosen-sidebar-btn-text': routeName == 'forceUpdate' }"
            >
              مدیریت بروزرسانی
            </div>
          </router-link>
        </button>
      </div>
      <p class="version">v 1.0</p>
    </div>
  </div>
  <div
    class="modal fade"
    id="confirmModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <div class="d-flex gap-2 mt-4 mb-3">
            <i class="bi bi-exclamation-circle text-blue"></i>
            <p class="fw-bold">خروج از حساب کاربری</p>
          </div>
          <p>آیا میخواهید از حساب کاربری‌تان خارج شوید؟</p>
          <div class="d-flex gap-3 mt-4">
            <button
              class="btn btn-outline-dark-gray col-6"
              data-bs-dismiss="modal"
              id="closeModal"
            >
              انصراف
            </button>
            <button
              class="btn btn-blue col-6"
              data-bs-dismiss="modal"
              @click="userLogout"
            >
              تایید
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      darkMode: null,
    };
  },
  methods: {
    userLogout() {
      this.axios
        .get(this.logout)
        .then(() => {
          this.$cookies.remove("Aalulbayte_api_token");
          localStorage.clear();
          this.$router.push({ name: "Login" });
        })
        .catch((err) => {
          this.$notify(
            {
              group: "danger",
              text: "بروز خطا سمت سرور",
            },
            2000
          );
        });
    },
    toggleTheme() {
      this.darkMode = this.darkMode ? false : true; //toggles theme value
      document.documentElement.setAttribute("data-theme", this.darkMode); // sets the data-theme attribute
      localStorage.setItem("darkMode", JSON.stringify(this.darkMode)); // stores theme value on local storage
    },
    changeCategory(category) {
      this.$store.dispatch("runChangeCategory", this.categories[category]);
    },
  },
  computed: {
    routeName() {
      if (this.$route.meta.type == "category") {
        return this.categoryName;
      } else {
        return this.$route.meta.type;
      }
    },
    role() {
      return JSON.parse(localStorage.getItem("user_Aalulbayte")).enRole;
    },
    ...mapGetters(["categoryName"]),
  },
  mounted() {
    let localTheme = JSON.parse(localStorage.getItem("darkMode")); //gets stored theme value if any
    document.documentElement.setAttribute("data-theme", localTheme);
    this.darkMode = JSON.parse(localStorage.getItem("darkMode")); // updates the data-theme attribute
  },
};
</script>

<style lang="scss" scoped>
.dashboard {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}

.main-offcanvas {
  background-color: var(--background-color);
  max-height: 100vh;
  overflow-y: scroll;
  position: fixed;
  right: 0;
  z-index: 1041;
  width: 295px;
  height: 100vh;
  box-shadow: -10px 0px 35px var(--app-bar-box);
  padding-inline: 0.5rem;
  padding-bottom: 2rem;
  border-left: none !important;
}

.logo-title {
  padding-top: 1rem;
  font-size: 14px;
}

.sidebar-btn {
  width: 100%;
  text-decoration: none;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  color: var(--app-bar-text);
  padding: 1rem 0;
  border-bottom: 1px solid var(--app-bar-border);
}

.chosen-sidebar-btn {
  width: 4px;
  height: 24px;
  background-color: #2085d7;
  border-radius: 20px;
  animation: sidebar-btn 0.5s linear;
}

.chosen-sidebar-btn-text {
  font-weight: 700;
  padding-top: 0.25rem;
  color: var(--app-bar-chosen);
  animation: sidebar-btn-text 0.1s;
}

::-webkit-scrollbar {
  width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--background-color);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@keyframes sidebar-btn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes sidebar-btn-text {
  0% {
    font-weight: normal;
  }

  100% {
    font-weight: bolder;
  }
}

.app-bar {
  position: fixed;
  z-index: 3;
  width: 100%;
  background-color: var(--background-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 2.25rem 1rem;
}

.sidebar-logo {
  width: 40%;
  height: 152px;
}

.sidebar-logo-text {
  margin-top: 1rem;
  width: 82%;
  height: 32px;
}

.main-sidebar-btn {
  background-color: #ffffff;
  border: none;
  font-size: 20px;
}

.app-bar-btn {
  box-shadow: 0px 0px 4px var(--app-bar-text);
  border-radius: 4px;
  border: none;
  color: var(--app-bar-text);
  background-color: var(--background-color);
  width: 36px;
  height: 36px;
  font-size: 20px;
}

.bi-brightness-low-fill {
  font-size: 25px;
}

.router {
  background-color: var(--background-color);
  color: var(--app-bar-text);
  border: none;
}

.version {
  padding-top: 1rem;
  width: 100%;
  color: var(--app-bar-text);
  text-align: left;
}

@media screen and (max-width: 991.5px) {
  .main-offcanvas {
    width: 258px !important;
  }

  .app-bar {
    width: 100%;
    padding: 1rem;
    box-shadow: 0px 2px 4px var(--app-bar-box);
    justify-content: space-between;
  }

  .dashboard {
    display: block;
  }
}

@media screen and (max-width: 426px) {
  .main-offcanvas {
    width: 220px !important;
  }

  .main-sidebar-btn {
    font-size: 20px;
  }

  .app-bar-btn {
    font-size: 20px;
  }

  .sidebar-logo {
    width: 56%;
    height: 156px;
  }

  .sidebar-logo-text {
    width: 86%;
    height: 26px;
  }
}
</style>
