<template>
  <NotificationGroup group="success">
    <div class="py-6 notification">
      <div class="">
        <Notification v-slot="{ notifications }" enter-active-class="animate__animated animate__fadeInRight"
          leave-active-class="animate__animated animate__fadeOutRight">
          <div class="mt-4 notification-body success" v-for="notification in notifications" :key="notification.id">
            <div class="px-4 py-2">
              <div class="d-flex align-items-center gap-3 ps-5">
                <i class="bi bi-check-circle"></i>
                <p class="text-sm text-gray-600">{{ notification.text }}</p>
              </div>
            </div>
          </div>
        </Notification>
      </div>
    </div>
  </NotificationGroup>

  <NotificationGroup group="danger">
    <div class="py-6 notification">
      <div class="">
        <Notification v-slot="{ notifications }" enter-active-class="animate__animated animate__fadeInRight"
          leave-active-class="animate__animated animate__fadeOutRight">
          <div class="mt-4 notification-body danger" v-for="notification in notifications" :key="notification.id">
            <div class="px-4 py-2">
              <div class="d-flex align-items-center gap-3 ps-5">
                <i class="bi bi-x-circle"></i>
                <p class="text-sm text-gray-600">{{ notification.text }}</p>
              </div>
            </div>
          </div>
        </Notification>
      </div>
    </div>
  </NotificationGroup>

  <NotificationGroup group="info">
    <div class="py-6 notification">
      <div class="">
        <Notification v-slot="{ notifications }" enter-active-class="animate__animated animate__fadeInRight"
          leave-active-class="animate__animated animate__fadeOutRight">
          <div class="mt-4 notification-body info" v-for="notification in notifications" :key="notification.id">
            <div class="px-4 py-2">
              <div class="d-flex align-items-center gap-3 ps-5">
                <i class="bi bi-exclamation-circle"></i>
                <p class="text-sm text-gray-600">{{ notification.text }}</p>
              </div>
            </div>
          </div>
        </Notification>
      </div>
    </div>
  </NotificationGroup>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.notification {
  position: fixed;
  z-index: 9999;
}

.notification-body {
  font-size: 16px;
  border-radius: 24px 0px 0px 24px;
  padding-left: 100px;
}

.success {
  background-color: #C8FFE4;
  color: #079E56;
}

.danger {
  background-color: #FFD2D2;
  color: #CE1F1F;
}

.info {
  background-color: #DFF0FD;
  color: #2085D7;
}

@media screen and (max-width:500px) {
  .notification-body {
    padding-left: 0px;
  }
}
</style>