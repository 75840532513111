<template>
  <div class="loading">
    <div
      class="lds-dual-ring position-absolute top-100 start-50 translate-middle"
    ></div>
  </div>
</template>

<style scoped>
.loading {
  position: relative;
  height: 34vh;
}

.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid var(--btn-blue);
  border-color: var(--btn-blue) transparent var(--btn-blue) transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
