import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

export default createStore({
  state: {
    category: {
      name: "",
      persianName: "",
      id: "",
    },
  },
  getters: {
    categoryName(state) {
      return state.category.name;
    },
    categoryPersianName(state) {
      return state.category.persianName;
    },
    categoryId(state) {
      return state.category.id;
    },
  },
  mutations: {
    changeCategory(state, payload) {
      state.category = { ...payload };
    },
  },
  actions: {
    runChangeCategory: ({ commit }, payload) => {
      commit("changeCategory", payload);
    },
  },
  modules: {},
  plugins: [createPersistedState()],
});
