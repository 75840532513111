export default {
  data() {
    return {
      categories: {
        sahifa: {
          persianName: "صحیفه سجادیه",
          name: "sahifa",
          id: 1,
        },
        munajat: {
          persianName: "مناجات خمس عشر",
          name: "munajat-khamsa-ashar",
          id: 2,
        },
        risalat: {
          persianName: "رساله حقوق",
          name: "risalat-al-huquq",
          id: 3,
        },
      },
      languages: {
        persian: {
          persianName: "فارسی",
          name: "persian",
          id: 1,
        },
        arabic: {
          persianName: "عربی",
          name: "arabic",
          id: 2,
        },
      },
      roles: {
        admin: { persianName: "ادمین", name: "admin", id: 1 },
        editor: { persianName: "محقق", name: "editor", id: 2 },
        operator: { persianName: "اپراتور", name: "operator", id: 3 },
      },
    };
  },
  methods: {
    checkLang(langId) {
      let langs = Object.values(this.languages);
      let languagePersianName = langs.find(
        (lang) => lang.id == langId
      ).persianName;
      return languagePersianName;
    },
    checkRole(userRole) {
      let roles = Object.values(this.roles);
      let rolePersianName = roles.find(
        (role) => role.name == userRole
      ).persianName;
      return rolePersianName;
    },
    checkCategory(categoryId) {
      let categories = Object.values(this.categories);
      let categoryPersianName = categories.find(
        (category) => category.id == categoryId
      ).persianName;
      return categoryPersianName;
    },
  },
};
