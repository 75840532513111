import { h, resolveComponent } from "vue";
import { createRouter, createWebHistory } from "vue-router";
import { useCookies } from "vue3-cookies";
import defaultLayout from "../layout/defaultLayout.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: defaultLayout,
    redirect: "/dashboard",
    children: [
      {
        path: "/",
        name: "dashboard",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        meta: {
          type: "dashboard",
        },
        redirect: "/dashboard",
        children: [
          {
            path: "/dashboard",
            name: "dashboard",
            meta: {
              type: "dashboard",
            },
            component: () => import("@/views/dashboard/dashboard.vue"),
          },
        ],
      },
      {
        path: "/category",
        name: "category",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        meta: {
          type: "category",
        },
        children: [
          {
            path: "/:categoryName/dashboard",
            name: "categoryDashboard",
            meta: {
              type: "category",
            },
            component: () => import("@/views/category/dashboard.vue"),
          },
          {
            path: "/:categoryName/editTitle/CI-:chapterId/CN-:chapterNum",
            name: "categoryEditTitle",
            meta: {
              type: "category",
            },
            beforeEnter: checkRole,
            component: () =>
              import("@/views/category/categoryPart/editTitle.vue"),
          },
          {
            path: "/:categoryName/detail/CI-:chapterId/CN-:chapterNum",
            name: "categoryDetail",
            meta: {
              type: "category",
            },
            component: () => import("@/views/category/categoryPart/detail.vue"),
          },
          {
            path: "/:categoryName/translation/CI-:chapterId/SI-:sectionId/CN-:chapterNum",
            name: "categoryTranslate",
            meta: {
              type: "category",
            },
            component: () =>
              import("@/views/category/categoryPart/translation.vue"),
          },
          {
            path: "/:categoryName/translation/TI-:translationId/CN-:chapterNum/SN-:sectionNum/edit",
            name: "categoryTranslateEdit",
            meta: {
              type: "category",
            },
            component: () =>
              import("@/views/category/categoryPart/translationEdit.vue"),
          },
          {
            path: "/:categoryName/commentary/CI-:chapterId/SI-:sectionId/CN-:chapterNum",
            name: "categoryCommentary",
            meta: {
              type: "category",
            },
            component: () =>
              import("@/views/category/categoryPart/commentary.vue"),
          },
          {
            path: "/:categoryName/commentary/DI-:descriptorId/CN-:chapterNum/SN-:sectionNum/edit",
            name: "categoryCommentaryEdit",
            meta: {
              type: "category",
            },
            component: () =>
              import("@/views/category/categoryPart/commentaryEdit.vue"),
          },
          {
            path: "/:categoryName/management/translation",
            name: "translationManagement",
            beforeEnter: adminAndEditor,
            meta: {
              type: "category",
            },
            children: [
              {
                path: "/:categoryName/management/translation/dashboard",
                name: "translationDashboard",
                meta: {
                  type: "category",
                },
                component: () =>
                  import("@/views/category/translation/dashboard.vue"),
              },
              {
                path: "/:categoryName/management/translation/addTranslator",
                name: "translationAddTranslator",
                meta: {
                  type: "category",
                },
                component: () =>
                  import("@/views/category/translation/addTranslator.vue"),
              },
              {
                path: "/:categoryName/management/translation/editTranslator/TRI-:translatorId",
                name: "translationEditTranslator",
                meta: {
                  type: "category",
                },
                component: () =>
                  import("@/views/category/translation/editTranslator.vue"),
              },
              {
                path: "/:categoryName/management/translation/default",
                name: "translationDefault",
                beforeEnter: checkRole,
                meta: {
                  type: "category",
                },
                component: () =>
                  import("@/views/category/translation/default.vue"),
              },
              {
                path: "/:categoryName/management/translation/default/LI-:langId",
                name: "translationDefaultSelection",
                beforeEnter: checkRole,
                meta: {
                  type: "category",
                },
                component: () =>
                  import("@/views/category/translation/defaultSelection.vue"),
              },
              {
                path: "/:categoryName/management/translation/edit/TRI-:translatorId",
                name: "translationEdit",
                meta: {
                  type: "category",
                },
                component: () =>
                  import("@/views/category/translation/edit.vue"),
              },
              {
                path: "/:categoryName/management/translation/editTranslate/TRI-:translatorId/CN-:chapterNum/SN-:sectionNum",
                name: "translationEditTranslate",
                meta: {
                  type: "category",
                },
                component: () =>
                  import("@/views/category/translation/editTranslate.vue"),
              },
            ],
          },
          {
            path: "/:categoryName/management/commentary",
            name: "commentaryManagement",
            beforeEnter: adminAndEditor,
            meta: {
              type: "category",
            },
            children: [
              {
                path: "/:categoryName/management/commentary/dashboard",
                name: "commentaryDashboard",
                meta: {
                  type: "category",
                },
                component: () =>
                  import("@/views/category/commentary/dashboard.vue"),
              },
              {
                path: "/:categoryName/management/commentary/addDescriptor",
                name: "commentaryAddDescriptor",
                meta: {
                  type: "category",
                },
                component: () =>
                  import("@/views/category/commentary/addDescriptor.vue"),
              },
              {
                path: "/:categoryName/management/commentary/editDescriptor/DRI-:descriptorId",
                name: "commentaryEditDescriptor",
                meta: {
                  type: "category",
                },
                component: () =>
                  import("@/views/category/commentary/editDescriptor.vue"),
              },
              {
                path: "/:categoryName/management/commentary/edit/DRI-:descriptorId",
                name: "commentaryEdit",
                meta: {
                  type: "category",
                },
                component: () => import("@/views/category/commentary/edit.vue"),
              },
              {
                path: "/:categoryName/management/commentary/editCommentary/DRI-:descriptorId/CN-:chapterNum/SN-:sectionNum",
                name: "commentaryEditCommentary",
                meta: {
                  type: "category",
                },
                component: () =>
                  import("@/views/category/commentary/editCommentary.vue"),
              },
            ],
          },
        ],
      },
      {
        path: "/poems",
        name: "poems",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        meta: {
          type: "poems",
        },
        redirect: "/poems/dashboard",
        children: [
          {
            path: "/poems/dashboard",
            name: "poemsDashboard",
            meta: {
              type: "poems",
            },
            component: () => import("@/views/poems/poemsDashboard.vue"),
          },
        ],
      },
      {
        path: "/biography",
        name: "biography",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        meta: {
          type: "biography",
        },
        redirect: "/biography/dashboard",
        children: [
          {
            path: "/biography/dashboard",
            name: "biographyDashboard",
            meta: {
              type: "biography",
            },
            component: () => import("@/views/biography/biographyDashboard.vue"),
          },
        ],
      },
      {
        path: "/hadiths",
        name: "hadiths",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        meta: {
          type: "hadiths",
        },
        redirect: "/hadiths/dashboard",
        children: [
          {
            path: "/hadiths/dashboard",
            name: "hadithsDashboard",
            meta: {
              type: "hadiths",
            },
            component: () => import("@/views/hadiths/hadithsDashboard.vue"),
          },
        ],
      },
      {
        path: "/users",
        name: "users",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        meta: {
          type: "users",
        },
        redirect: "/users/dashboard",
        beforeEnter: checkRole,
        children: [
          {
            path: "/users/dashboard",
            name: "usersDashboard",
            meta: {
              type: "users",
            },
            component: () => import("@/views/users/usersDashboard.vue"),
          },
          {
            path: "/users/detail/:id",
            name: "userDetail",
            meta: {
              type: "users",
            },
            component: () => import("@/views/users/userDetail.vue"),
          },
          {
            path: "/users/edit/:id",
            name: "userEdit",
            meta: {
              type: "users",
            },
            component: () => import("@/views/users/userEdit.vue"),
          },
          {
            path: "/users/changePassword/:id",
            name: "userChangePassword",
            meta: {
              type: "users",
            },
            component: () => import("@/views/users/userChangePassword.vue"),
          },
          {
            path: "/users/create",
            name: "userCreate",
            meta: {
              type: "users",
            },
            component: () => import("@/views/users/userCreate.vue"),
          },
        ],
      },
      {
        path: "/message",
        name: "message",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        meta: {
          type: "message",
        },
        redirect: "/message/dashboard",
        children: [
          {
            path: "/message/dashboard",
            name: "messageDashboard",
            meta: {
              type: "message",
            },
            component: () => import("@/views/message/messageDashboard.vue"),
          },
          {
            path: "/message/newMessage",
            name: "newMessage",
            meta: {
              type: "message",
            },
            component: () => import("@/views/message/newMessage.vue"),
          },
          {
            path: "/message/editMessage/:messageId",
            name: "editMessage",
            meta: {
              type: "message",
            },
            component: () => import("@/views/message/editMessage.vue"),
          },
        ],
      },
      {
        path: "/contentUpdate",
        name: "contentUpdate",
        beforeEnter: checkRole,
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        meta: {
          type: "contentUpdate",
        },
        redirect: "/contentUpdate/dashboard",
        children: [
          {
            path: "/contentUpdate/dashboard",
            name: "contentUpdateDashboard",
            meta: {
              type: "contentUpdate",
            },
            component: () => import("@/views/contentUpdate/dashboard.vue"),
          },
          {
            path: "/contentUpdate/detail/:updateId",
            name: "contentUpdateDetail",
            meta: {
              type: "contentUpdate",
            },
            component: () => import("@/views/contentUpdate/detail.vue"),
          },
          {
            path: "/contentUpdate/new",
            name: "contentUpdateNew",
            meta: {
              type: "contentUpdate",
            },
            component: () => import("@/views/contentUpdate/new.vue"),
          },
        ],
      },
      {
        path: "/forceUpdate",
        name: "forceUpdate",
        beforeEnter: checkRole,
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        meta: {
          type: "forceUpdate",
        },
        redirect: "/forceUpdate/dashboard",
        children: [
          {
            path: "/forceUpdate/dashboard",
            name: "forceUpdateDashboard",
            meta: {
              type: "forceUpdate",
            },
            component: () => import("@/views/forceUpdate/dashboard.vue"),
          },
          {
            path: "/forceUpdate/edit/:versionId",
            name: "forceUpdateEdit",
            meta: {
              type: "forceUpdate",
            },
            component: () => import("@/views/forceUpdate/edit.vue"),
          },
          {
            path: "/forceUpdate/new",
            name: "forceUpdateNew",
            meta: {
              type: "forceUpdate",
            },
            component: () => import("@/views/forceUpdate/new.vue"),
          },
        ],
      },
      {
        path: "/notification",
        name: "notification",
        beforeEnter: checkRole,
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        meta: {
          type: "notification",
        },
        redirect: "/notification/dashboard",
        children: [
          {
            path: "/notification/dashboard",
            name: "notificationDashboard",
            meta: {
              type: "notification",
            },
            component: () => import("@/views/notification/dashboard.vue"),
          },
        ],
      },
    ],
  },
  {
    path: "/pages",
    redirect: "/pages/404",
    name: "Pages",
    component: {
      render() {
        return h(resolveComponent("router-view"));
      },
    },
    children: [
      {
        path: "login",
        name: "Login",
        component: () => import("@/views/pages/login.vue"),
      },
      {
        path: "page404",
        name: "Page404",
        component: () => import("@/views/pages/page404.vue"),
      },
      {
        path: "noAccess",
        name: "noAccess",
        component: () => import("@/views/pages/noAccess.vue"),
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    component: () => import("@/views/pages/page404.vue"),
  },
];

function checkRole(to, from, next) {
  if (JSON.parse(localStorage.getItem("user_Aalulbayte")).enRole == "admin") {
    next();
  } else {
    next({ name: "noAccess" });
  }
}

function adminAndEditor(to, from, next) {
  if (
    JSON.parse(localStorage.getItem("user_Aalulbayte")).enRole == "admin" ||
    JSON.parse(localStorage.getItem("user_Aalulbayte")).enRole == "editor"
  ) {
    next();
  } else {
    next({ name: "noAccess" });
  }
}

const router = createRouter({
  history: createWebHistory(process.env.BASEURL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 };
  },
});

router.beforeEach((to, from, next) => {
  if (useCookies().cookies.get("Aalulbayte_api_token")) {
    if (to.name == "Login") router.push({ name: "home" });
  } else {
    if (to.name !== "Login")
      router.push({
        name: "Login",
      });
  }
  next();
});

export default router;
